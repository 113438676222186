<div class="container-single-searchable">
  <mat-select class="searchable-select" [value]="selectedData" [class.hide-arrow]="!showSelectArrow"
    (selectionChange)="onSelectionChange($event.value)" (openedChange)="onOpen($event)">
    <input #searchField matInput type="text" class="filter" (input)="onSearch()" autocomplete="off"
      (keydown)="ignoreSpaceKey($event)">
    <mat-divider></mat-divider>
    <mat-select-trigger *ngIf="stickyPlaceholder">{{placeholder}}</mat-select-trigger>
    <mat-option *ngFor="let filteredEntry of filteredData" [value]="filteredEntry">
      <span>{{ filteredEntry.name }}</span>
    </mat-option>
  </mat-select>
  <div>
    <mat-icon *ngIf="selectedData" (click)="resetSelectedData()" class="container-single-searchable__cancel-data">close</mat-icon>
  </div>
</div>

