import { NgFor, NgIf } from '@angular/common';
import {Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelect } from "@angular/material/select"
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: "single-searchable-select",
    templateUrl: "./single-searchable-select.component.html",
    styleUrls: ["./single-searchable-select.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SingleSearchableSelectComponent
        }
    ],
    standalone: true,
    imports: [NgFor, NgIf, MatInputModule, MatSelectModule, MatDividerModule, MatIconModule]
})
export class SingleSearchableSelectComponent implements OnInit, ControlValueAccessor {

  @Input() data: { id: string, name: string }[] = []
  @Input() showSelectArrow = false
  @Input() placeholder = ''
  @Input() stickyPlaceholder = false


  @ViewChild("searchField") searchField!: ElementRef
  @ViewChild(MatSelect, { static: true }) matSelect!: MatSelect

  filteredData: { id: string, name: string }[] = []
  selectedData: { id: string, name: string } | null = null
  touched = false
  disabled = false

  onChange = (value: any) => { }
  onTouched = () => { }

  ngOnInit(): void {
    this.matSelect.placeholder = this.placeholder
    this.matSelect.disabled = this.data.length < 1 || this.disabled
    this.filteredData = this.data
  }

  writeValue(id: string): void {
    const selectedData = this.data.find(current => current.id == id)
    if (selectedData) {
      this.selectedData = selectedData
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled
    this.matSelect.disabled = disabled
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  ignoreSpaceKey($event: KeyboardEvent) {
    if ($event.key === " ") {
      this.searchField.nativeElement.value += $event.key
      this.onSearch()
      this.stopPropagation($event)
    }
  }

  onSearch() {
    const filter = this.searchField.nativeElement.value
    this.filteredData = filter ? this.data.filter(current => (current.name.toLocaleLowerCase().includes(filter.toLowerCase()))) : this.data
  }

  onOpen($event: boolean) {
    const open = $event
    this.markAsTouched()
    if (open) {
      this.searchField.nativeElement.focus()
    } else {
      this.searchField.nativeElement.value = ""
      this.onSearch()
    }
  }

  onSelectionChange(selectedData: any) {
    this.selectedData = selectedData
    this.onChange(this.selectedData?.id)
  }

  stopPropagation($event: any) {
    $event.preventDefault()
    $event.stopPropagation()
  }

  resetSelectedData() {
    this.selectedData = null
    this.onChange(null)
  }
}
